@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/Montserrat-Regular.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: "Montserrat Bold";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Montserrat-Bold.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: "Spring LP Regular";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(/fonts/Spring-LP-Regular.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: HindMadurai-Regular;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(/fonts/HindMadurai-Regular.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

:root {
    --brown-color-lighter: #ebdccc;
    --brown-color: #c5b199;
    --brown-color-darker: #81684b;
    --white-color: white;
    --black-color: black;
    --pink-color: #d2668d;
    --on-surface-brown: #4f4539;
    --grey-color: #72777b;
    --background-color: #eeeeee
}

body,
html {
    display: block;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    padding: 0;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background: var(--background-color);
    font-size: calc(16px + .390625vw);
    word-spacing: 0;
    letter-spacing: 0;
    max-width: 100%;
}

body {
    margin: 0;
}

button,
hr,
input {
    overflow: visible
}

progress,
sub,
sup {
    vertical-align: baseline
}

[type=checkbox],
[type=radio],
legend {
    box-sizing: border-box;
    padding: 0
}

details,
main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    box-sizing: content-box;
    height: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: ButtonText dotted 1px
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal
}

textarea {
    overflow: auto
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

[hidden],
template {
    display: none
}

.p-universal {
    padding: calc(8px + 1.5625vw)
}

.p-universal-s {
    padding: calc(4px + 1vw)
}

.p-universal-lr {
    padding-left: calc(8px + 1.5625vw);
    padding-right: calc(8px + 1.5625vw)
}

.p-universal-tb {
    padding-top: calc(8px + 1.5625vw);
    padding-bottom: calc(8px + 1.5625vw);
}

.p-universal-tb-s {
    padding-top: calc(4px + 1.1625vw);
    padding-bottom: calc(4px + 1.1625vw);
}

.p-universal-b {
    padding-bottom: calc(8px + 1.5625vw);
}

.p-universal-t {
    padding-top: calc(8px + 1.5625vw),
}

.p-universal-b-s {
    padding-bottom: calc(4px + 1vw);
}

.p-universal-t-s {
    padding-top: calc(4px + 1vw);
}

.m-universal {
    margin: calc(8px + 1.5625vw)
}

.m-universal-tb {
    margin-top: calc(8px + 1.5625vw);
    margin-bottom: calc(8px + 1.5625vw);
}

.m-universal-t {
    margin-top: calc(8px + 1.5625vw);
}

.m-universal-b {
    margin-bottom: calc(8px + 1.5625vw);
}

.m-universal-s {
    margin-top: calc(4px + 1vw);
    margin-bottom: calc(4px + 1vw);
}

.p-all-s {
    padding: .07rem
}

.p-all-m {
    padding: .2rem
}

.p-all-l {
    padding: .25rem
}

.m-all-s {
    margin: .07rem
}

.m-all-m {
    margin: .2rem
}

.m-all-l {
    margin: .25rem
}

.m-top-xs {
    margin-top: .01rem
}

.m-right-xs {
    margin-right: .01rem
}

.p-bottom-xs {
    padding-bottom: .01rem
}

.p-left-xs {
    padding-left: .01rem
}

.p-top-s {
    padding-top: .07rem
}

.p-right-s {
    padding-right: .07rem
}

.p-bottom-s {
    padding-bottom: .07rem
}

.p-left-s {
    padding-left: .07rem
}

.p-top-m {
    padding-top: .2rem
}

.b-top-custom {
    border-top: .5rem
}

.p-right-m {
    padding-right: .2rem
}

.p-bottom-m {
    padding-bottom: .2rem
}

.p-left-m {
    padding-left: .2rem
}

.p-top-l {
    padding-top: .25rem
}

.p-right-l {
    padding-right: .25rem
}

.p-bottom-l {
    padding-bottom: .25rem
}

.p-left-l {
    padding-left: .25rem
}

.p-bottom-xs {
    margin-bottom: .01rem
}

.p-left-xs {
    margin-left: .01rem
}

.m-top-xs {
    margin-top: .01rem
}

.m-right-xs {
    margin-right: .01rem
}

.m-top-s {
    margin-top: .07rem
}

.m-right-s {
    margin-right: .07rem
}

.m-bottom-s {
    margin-bottom: .07rem
}

.m-left-s {
    margin-left: .07rem
}

.m-top-m {
    margin-top: .2rem
}

.m-left-m {
    margin-left: .2rem
}

.m-bottom-m {
    margin-bottom: .2rem
}

.m-bottom-m {
    margin-bottom: .42rem
}

.m-right-m {
    margin-right: .2rem
}


.banner {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    box-sizing: border-box;
    min-width: 275px;
    max-width: 375px;
    margin: 10px 10px;
    padding: 2px 20px;
    border-radius: 20px;
    box-shadow: 10px 10px 36px -13px rgba(0, 0, 0, .15)
}

.cookie-consent-banner__inner {
    padding: 15px 0
}

.cookie-consent-banner__copy {
    margin-bottom: 20px
}

.cookie-consent-banner__description {
    font-weight: 400
}

.buttons {
    display: flex;
    justify-content: space-around
}

.cookie-consent-btn {
    display: flex;
    align-items: center;
    padding: 11px 13px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    text-align: center;
    transition: .4s
}

.cookie-consent-btn:hover {
    text-decoration: none;
    opacity: .9
}

.cookie-consent-btn-secondary {
    display: flex;
    align-items: center;
    padding: 11px 13px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    text-align: center;
    transition: .3s
}

.cookie-consent-btn-secondary:hover {
    text-decoration: none;
    opacity: .9
}

.prebanner {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 50px;
    left: 50px;
    z-index: 999;
    height: 45px;
    width: 45px;
    border: none;
    border-radius: 100%;
    text-decoration: none;
    box-shadow: 10px 10px 36px -13px rgba(0, 0, 0, .15)
}

.prebanner-left {
    left: 21px;
    bottom: 21px
}

.prebanner-right {
    right: 0
}

.prebanner:hover {
    text-decoration: none
}

.link-btn {
    text-decoration: underline;
    border: none;
    background-color: inherit;
    margin: 0;
    padding: 0
}

.link-btn:hover {
    text-decoration: none
}

.container {
    --bs-gutter-x: 3.5rem;
    --bs-gutter-y: 0;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    flex-direction: initial
}

.row-nested {
    display: flex;
    flex-wrap: wrap;
    flex-direction: initial;
    justify-content: space-between;
}

.row-no-flex {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row-flex-direction {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    flex-direction: column-reverse
}

img {
    vertical-align: middle;
    margin: 0 auto;
    max-width: 100%
}

[class*=col-] {
    position: relative
}

.website-by {
    font-size: calc(12px + .390625vw)
}

.website-by p {
    margin-top: .3rem;
    margin-bottom: .3rem
}

.t-color-black-white {
    color: white;
}


.container-no-p-m {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 0;
    margin: 0
}

.row-no-p-m {
    margin: 0;
    padding: 0
}

.row-no-flex {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.visuallyhidden {
    display: none;
}

.fw {
    width: 100%
}

svg#svg334 {
    background-color: white;
}

.w-fit-content {
    width: -moz-fit-content;
    width: fit-content;
}

.h-fit-content {
    height: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.d-flex-ac {
    display: flex;
    align-content: center;
    align-items: center
}

.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center
}

.d-flex-end {
    display: flex;
    justify-content: end;
    align-items: center;
    align-content: center
}

.d-flex-ae {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.d-flex-start {
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center
}

.d-flex-all {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.d-flex-all-nai {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.d-flex-all-r {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row
}

.d-flex-all-rs {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.d-flex-no-jc {
    display: flex;
    align-items: center;
    width: 100%
}

.d-flex-space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center
}

.display-none-mobile,
.display-none-mobile-s {
    display: none;
}

.margin-tb {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

@media (max-width: 989px) {
    .d-flex-space-between {
        justify-content: center;
    }
}

.text-align {
    text-align: center
}

.text-align-cc {
    text-align: center;
}

.text-align-right {
    text-align: center;
}

.text-align-left {
    text-align: center;
}

.margin-lr-auto {
    margin-left: auto;
    margin-right: auto
}

.margin-tb {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.margin-tb-s {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.float-right {
    float: right
}

a,
p,
ul li {
    font-family: HindMadurai-Regular, sans-serif
}

.moving-item-list li {
    padding: calc(2px + 0.33vw);
}

.a-no-underline {
    text-decoration: none
}

.b-blur {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px)
}

.b-color-background {
    background: var(--background-color)
}

.b-color-background-trsp {
    backdrop-filter: blur(2px) saturate(200%);
    -webkit-backdrop-filter: blur(2px) saturate(200%);
    background-color: rgba(255, 255, 255, .75)
}

.b-color-brown-lighter {
    background: var(--brown-color-lighter)
}

.b-color-black {
    background: black;
}

.b-color-black-trsp {
    backdrop-filter: blur(2px) saturate(200%);
    -webkit-backdrop-filter: blur(2px) saturate(200%);
    background: rgba(0, 0, 0, .75)
}

.b-color-brown {
    background: var(--brown-color)
}

.b-color-brown-darker {
    background: var(--brown-color-darker)
}

.b-color-grey {
    background: var(--grey-color)
}

.b-color-brown-trsp {
    background: rgba(197, 177, 153, .7)
}

.b-color-white-trsp-light {
    background: rgba(255, 255, 255, .7)
}

.b-color-gradient {
    background-color: #fff;
    background: linear-gradient(147deg, #fff 21%, #c5b199 100%)
}

.b-color-gradient-2 {
    background-color: #fff;
    background: linear-gradient(315deg, #fff 21%, #c5b199 100%)
}

.b-color-white-trsp-strong {
    background: rgba(255, 255, 255, .9)
}

.b-color-white {
    background: var(--white-color)
}


.b-color-pink {
    background: var(--pink-color)
}

.t-color-white,
.t-color-white a {
    color: var(--white-color)
}

.t-color-black,
.t-color-black a {
    color: var(--black-color)
}

.t-color-pink {
    color: var(--pink-color)
}

.t-color-brown-on {
    color: var(--on-surface-brown)
}

.t-color-black-on {
    color: var(--black-color);
    font-weight: 700
}

ul {
    list-style-type: "";
    padding-left: 0
}

section {
    width: 100%
}

p {
    line-height: 1.5
}

a {
    position: relative;
    color: #000;
    line-height: 1.5;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: inline-block
}

a:hover {
    transform: scale(1.05);
    transition: .3s ease-in-out
}

a[title]:hover:after {
    content: attr(title);
    position: absolute;
    top: -100%;
    left: 0
}

video:not(.media-absolute-23 video) {
    width: 90%;
}

.media-absolute-23 video {
    width: 120%;
}

.elevation {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
    cursor: pointer
}

.img-round {
    border-radius: 100px
}

.banner-picture-wrapper {
    border-radius: 10px
}

.media-wrapper {
    position: relative;
    display: flex;
}

.pink {
    color: var(--pink-color);
    font-family: "Spring LP Regular"
}

.pink-2 {
    color: #ffe9ff;
    font-family: "Spring LP Regular"
}

.pink-3 {
    color: #a53d66;
    font-family: "Spring LP Regular"
}

.font-spring {
    font-family: "Spring LP Regular"
}

.font-mont-bold {
    font-family: "Montserrat Bold"
}

footer .pink {
    font-size: calc(19px + .390625vw)
}

.rezensionen-wrapper h3 {
    font-family: "Spring LP Regular";
    font-weight: 400;
    color: var(--pink-color)
}

.rezensionen-wrapper p:before:not(.fs-larger) {
    content: url(../iv/icons/quote.svg);
    position: absolute;
    width: 24px;
    height: 24px;
    left: -14px
}

.rezensionen-wrapper span {
    font-size: calc(7px + .390625vw)
}

.c-br-0 {
    border-radius: 10px
}

.c-br-1 {
    border-radius: 22% 78% 78% 22%/22% 24% 76% 78%
}

.c-br-2 {
    border-radius: 15% 85% 15% 85%/85% 15% 85% 15%
}

.c-br-3 {
    border-radius: 70% 30% 88% 12%/75% 40% 60% 25%
}

.c-br-4 {
    border-radius: 28% 72% 65% 35%/75% 22% 78% 25%
}

.slideUp {
    transform: translateY(-380px);
    position: fixed;
    top: 0;
    width: 100%
}

.slideDown {
    transform: translateY(0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
}

.slideDown,
.slideUp {
    transition: transform .5s ease-out;
    width: 100%
}

nav.navigation {
    top: 0;
    left: 0;
    width: 100%
}

nav.navigation .container .row-no-flex ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    text-align: right
}

nav.navigation.container .row-no-flex ul li {
    text-align: right
}

nav.navigation .container .row-no-flex ul li a {
    display: inline-block;
    padding: 20px 40px;
    text-decoration: none
}

nav.navigaton>ul>li>a:last-child {
    border-right: none
}

nav.navigation .menu-btn:hover,
nav.navigation>ul>li>a:hover {
    text-decoration: underline
}

nav.navigation .container .row-no-flex .menu-wrapper {
    padding: 21px 0 21px 0
}

nav.navigation .container .row-no-flex .menu-wrapper .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out
}

nav.navigation .container .row-no-flex .menu-icon {
    cursor: pointer;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    float: right;
    font-size: 0
}

nav.navigation .container .row-no-flex .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px
}

nav.navigation .container .row-no-flex .menu-icon .navicon:after,
nav.navigation .container .row-no-flex .menu-icon .navicon:before {
    background: #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%
}

nav.navigation .container .row-no-flex .menu-icon .navicon:before {
    top: 5px
}

nav.navigation .container .row-no-flex .menu-icon .navicon:after {
    top: -5px
}

nav.navigation .container .row-no-flex .menu-btn {
    display: none
}

nav.navigation .container .row-no-flex .menu-btn:checked~.menu-wrapper .menu {
    max-height: 350px
}

nav.navigation .container .row-no-flex .menu-btn:checked~.menu-icon .navicon {
    background: 0 0
}

nav.navigation .container .row-no-flex .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg)
}

nav.navigation .container .row-no-flex .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg)
}

nav.navigation .container .row-no-flex .menu-btn:checked~.menu-icon:not(.steps) .navicon:after,
nav.navigation .container .row-no-flex .menu-btn:checked~.menu-icon:not(.steps) .navicon:before {
    top: 0
}

nav.navigation .container .row-no-flex .theme-btn.menu-phone-button {
    display: none
}

nav.navigation .container .row-no-flex .menu-phone-button {
    float: right
}

.contact-details a,
.contact-details p {
    font-family: Montserrat, cursive
}

.first-section svg {
    fill: var(--pink-color)
}

.third-section svg {
    fill: var(--pink-color)
}

.contact-details svg {
    fill: var(--pink-color)
}

.media-absolute-21 {
    display: flex;
    justify-content: center
}

.mitarbeiterin-wrapper {
    position: relative;
    padding: 10px;
}


@media screen and (min-width: 992px) {
    .mitarbeiterin-wrapper {
        padding: 0px;
    }
}

@media screen and (min-width:768px) {

    .p-universal {
        padding: calc(4px + 1.1vw)
    }


    .display-none-mobile-s {
        display: block;
    }

    .d-flex-ae {
        align-items: end;
    }

    .margin-lr-start {
        margin-left: 0
    }

    .margin-lr-end {
        margin-right: 0;
    }

    .d-flex-center {
        justify-content: end;
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        flex-direction: initial
    }

    .row-flex-direction {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        flex-direction: initial
    }

    .text-align-right {
        text-align: right;
    }

    .text-align-left {
        text-align: left;
    }

    .text-align {
        text-align: left;
    }

    .text-align-cc {
        text-align: center;
    }
}

@media screen and (min-width:1024px) {
    nav.navigation .container .row-no-flex .menu-wrapper>ul>li {
        float: left
    }

    nav.navigation .container .row-no-flex .menu-wrapper>ul>li>a {
        padding: 20px 40px;
        font-weight: 700
    }

    nav.navigation .container .row-no-flex .menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    nav.navigation .container .row-no-flex .menu-wrapper .menu {
        clear: none;
        max-height: none;
        display: flex;
        align-items: center
    }

    nav.navigation .container .row-no-flex .menu-icon {
        display: none
    }

    nav.navigation .container .row-no-flex .theme-btn.menu-phone-button {
        display: block;
        float: right
    }

    .header-info-wrapper {
        font-size: calc(11px + .390625vw)
    }

    @keyframes scaleIn {
        from {
            transform: scale(0)
        }

        to {
            transform: scale(1)
        }
    }

    @keyframes scaleOut {
        from {
            transform: scale(1)
        }

        to {
            transform: scale(0)
        }
    }

    .zoom-in-scroll {
        animation: scaleIn 330ms ease-in;
        opacity: 1
    }

    .zoom-out-scroll {
        animation: scaleOut 330ms ease-out;
        opacity: 0;
        visibility: hidden
    }

    .header-imp-dsgvo,
    .header-index {
        background-image: url(../iv/friseurscharnstein_2560_1707.jpg);
        background-image: url(../iv/friseurscharnstein_2560_1707.webp);
        max-width: 100%;
        background-size: cover;
        background-attachment: fixed;
        background-position: bottom;
        height: 70vh
    }

    .header-imp-dsgvo {
        height: 50vh
    }

    .banner-wrapper svg {
        fill: #fff
    }

    [class*=media-absolute-] {
        display: inline
    }

    @supports (-webkit-touch-callout :none) {

        .header-imp-dsgvo,
        .header-index,
        .s-bckgi {
            background-attachment: scroll
        }
    }

    @supports not (-webkit-touch-callout :none) {

        .header-imp-dsgvo,
        .header-index,
        .s-bckgi {
            background-attachment: fixed
        }
    }

    .media-absolute-21 {
        position: absolute;
        top: -80px;
        left: 133px;
    }

    .media-absolute-22 {
        position: absolute;
        bottom: -600px;
        left: 200px;
        z-index: 1;
    }

    .media-absolute-23 {
        position: absolute;
        left: 350px;
    }

    .media-absolute-custom {
        position: absolute;
        bottom: 9px;
        right: -54px;
        left: 352px
    }

    .media-absolute-custom img {
        width: 60%
    }
}

.media-absolute-23 video {
    -webkit-clip-path: inset(5px 5px);
    clip-path: inset(5px 30px)
}

.mitarbeiterin-info-wrapper {
    position: absolute;
    bottom: 0;
    left: 0
}

.mitarbeiterin-info-wrapper-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.mitarbeiterin-info-wrapper-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.instagram-container {
    background-position: center;
    background-size: cover;
    background-attachment: fixed
}

.instagram-back-img {
    background-image: url(../iv/bernis-haarwerkstatt-instagram.jpg);
    background-image: url(../iv/bernis-haarwerkstatt-instagram.webp)
}


.instagram-wrapper a,
.short_explanation a,
form a {
    font-size: 0
}

.stars {
    color: #ff0;
    width: 21px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-family: "Montserrat Bold"
}

:root {
    --golden-ratio: 1.618
}

h1 {
    font-size: calc(2.986rem / var(--golden-ratio) + .390625vw)
}

h2 {
    font-size: calc(2.488rem / var(--golden-ratio) + .390625vw)
}

h3 {
    font-size: calc(2.074rem / var(--golden-ratio) + .390625vw)
}

h4 {
    font-size: calc(1.728rem / var(--golden-ratio) + .390625vw)
}

h5 {
    font-size: calc(1.44rem / var(--golden-ratio) + .390625vw)
}

.fs-larger {
    font-size: calc(1.44rem + .390625vw);
    margin: 0;
    padding: 0
}

@media screen and (min-width:960px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        font-family: "Montserrat Bold"
    }

    h1 {
        font-size: calc(2.986rem + .390625vw)
    }

    h2 {
        font-size: calc(2.488rem + .390625vw)
    }

    h3 {
        font-size: calc(2.074rem + .390625vw)
    }

    h4 {
        font-size: calc(1.728rem + .390625vw)
    }

    h5 {
        font-size: calc(1.44rem + .390625vw)
    }

    @keyframes animate {
        0% {
            transform: translateY(0) rotate(0);
            opacity: 1;
            border-radius: 0
        }

        100% {
            transform: translateY(-1000px) rotate(720deg) scale(.33);
            opacity: 0
        }
    }

    .background-logo,
    .background-reviews {
        position: relative;
        background: #ebdccc;
        overflow: hidden
    }

    .background-logo pre,
    .background-reviews pre {
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        animation: animate 19s linear infinite
    }

    .background-logo pre:before {
        content: url(../iv/icons/bernishaarwerkstattlogo.png);
        content: url(../iv/icons/bernishaarwerkstattlogo.webp)
    }

    .background-reviews pre:before {
        content: url(../iv/icons/star.svg);
        fill: #ff0
    }

    .background-logo pre:nth-child(0),
    .background-reviews pre:nth-child(0) {
        left: 3%;
        width: 163px;
        height: 163px;
        bottom: -250px;
        animation-delay: 1s
    }

    .background-logo pre:nth-child(1),
    .background-reviews pre:nth-child(1) {
        left: 55%;
        width: 167px;
        height: 167px;
        bottom: -250px;
        animation-delay: 2s
    }

    .background-logo pre:nth-child(2),
    .background-reviews pre:nth-child(2) {
        left: 8%;
        width: 177px;
        height: 177px;
        bottom: -250px;
        animation-delay: 6s
    }

    .background-logo pre:nth-child(3),
    .background-reviews pre:nth-child(3) {
        left: 60%;
        width: 142px;
        height: 142px;
        bottom: -250px;
        animation-delay: 7s
    }

    .background-logo pre:nth-child(4),
    .background-reviews pre:nth-child(4) {
        left: 79%;
        width: 192px;
        height: 192px;
        bottom: -250px;
        animation-delay: 3s
    }

    .background-logo pre:nth-child(5),
    .background-reviews pre:nth-child(5) {
        left: 86%;
        width: 145px;
        height: 145px;
        bottom: -250px;
        animation-delay: 8s
    }

    .background-logo pre:nth-child(5),
    .background-reviews pre:nth-child(5) {
        left: 9%;
        width: 123px;
        height: 123px;
        bottom: -250px;
        animation-delay: 24s
    }

    .background-logo pre:nth-child(6),
    .background-reviews pre:nth-child(6) {
        left: 84%;
        width: 103px;
        height: 103px;
        bottom: -250px;
        animation-delay: 6s
    }

    .background-logo pre:nth-child(7),
    .background-reviews pre:nth-child(7) {
        left: 85%;
        width: 143px;
        height: 143px;
        bottom: -250px;
        animation-delay: 32s
    }

    .background-logo pre:nth-child(8),
    .background-reviews pre:nth-child(8) {
        left: 45%;
        width: 184px;
        height: 184px;
        bottom: -250px;
        animation-delay: 37s
    }

    .background-logo pre:nth-child(9),
    .background-reviews pre:nth-child(9) {
        left: 60%;
        width: 162px;
        height: 162px;
        bottom: -250px;
        animation-delay: 7s
    }

    .background-logo pre:nth-child(10),
    .background-reviews pre:nth-child(10) {
        left: 13%;
        width: 144px;
        height: 144px;
        bottom: -250px;
        animation-delay: 45s
    }

    .background-logo pre:nth-child(11),
    .background-reviews pre:nth-child(11) {
        left: 1%;
        width: 193px;
        height: 193px;
        bottom: -250px;
        animation-delay: 10s
    }

    .background-logo pre:nth-child(12),
    .background-reviews pre:nth-child(12) {
        left: 24%;
        width: 191px;
        height: 191px;
        bottom: -250px;
        animation-delay: 35s
    }

    .background-logo pre:nth-child(13),
    .background-reviews pre:nth-child(13) {
        left: 47%;
        width: 179px;
        height: 179px;
        bottom: -250px;
        animation-delay: 21s
    }

    .background-logo pre:nth-child(14),
    .background-reviews pre:nth-child(14) {
        left: 50%;
        width: 134px;
        height: 134px;
        bottom: -250px;
        animation-delay: 39s
    }

    .background-logo pre:nth-child(15),
    .background-reviews pre:nth-child(15) {
        left: 19%;
        width: 157px;
        height: 157px;
        bottom: -250px;
        animation-delay: 50s
    }

    .background-logo pre:nth-child(16),
    .background-reviews pre:nth-child(16) {
        left: 83%;
        width: 196px;
        height: 196px;
        bottom: -250px;
        animation-delay: 14s
    }

    .background-logo pre:nth-child(17),
    .background-reviews pre:nth-child(17) {
        left: 12%;
        width: 143px;
        height: 143px;
        bottom: -250px;
        animation-delay: 43s
    }
}

.theme-btn {
    padding: 18px 30px 18px 30px;
    font-family: Montserrat, cursive;
    font-weight: 400;
    color: #fff;
    display: inline-flex;
    position: relative;
    text-decoration: none;
    border: none;
    transition: .3s ease-in-out;
    background-color: #815500;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
    cursor: pointer
}

.counter-style-one .counter-data h4 {
    font-size: 16px;
    font-weight: 400;
    color: #fff
}

.counter-style-one .counter-data {
    padding: 40px;
    position: relative;
    box-shadow: 20px 20px 13px 0 rgb(0 0 0 / .4);
    -webkit-box-shadow: 20px 20px 13px 0 rgb(0 0 0 / .4);
    -moz-box-shadow: 20px 20px 13px 0 rgb(0 0 0 / .4)
}

.counter-style-one .counter-data .count {
    font-size: calc(4rem + .390625vw);
    font-weight: 900;
    display: flex;
    justify-content: center;
}

.counter-style-one .counter-data .count i {
    font-size: calc(21px + .390625vw);
    font-weight: 100;
    line-height: 105px;
    display: flex;
    align-items: center;
    justify-content: center
}

.contact-form-2>.container:nth-child(2) {
    background: var(--header-color)
}

.contact-form-2 .data span {
    color: #444;
    font-family: Montserrat, cursive
}

.contact-form-2 .data h2 {
    margin-top: 5px
}

.contact-form-2 form .row {
    margin-bottom: 20px
}

.contact-form-2 form textarea:focus-visible {
    outline: 0
}

.position-sticky {
    position: sticky;
    top: 1rem
}

#contactus {
    position: relative
}

#contactus .inputcontainer {
    max-width: 100%;
}

#contactus fieldset {
    padding: 20px;
    border: medium none;
    max-width: 100%;
}

#contactus legend {
    font-size: 1.3em
}

#contactus input[type=text],
textarea {
    border: none;
    border-bottom: 1px solid;
    margin: 0 0 5px;
    resize: vertical;
    width: 100%;
    padding: 10px
}

#contactus input[type=text] {
    border: none;
    border-bottom: 1px solid;
    margin: 0 0 5px;
    padding: 10px
}

#contactus #scaptcha {
    width: 170px
}

#contactus input[type=submit] {
    height: 3rem;
    margin-top: 1rem;
    padding-left: 0;
    font-weight: 700;
    border: none;
    background-color: #8bc34a;
    color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    transition: all .3s cubic-bezier(.25, .8, .25, 1)
}

#contactus input[type=submit]:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22)
}

#contactus textarea {
    height: 11vh
}

#contactus input[type=text]:focus,
textarea:focus {
    border: 1px solid #900
}

#contactus input[type=text]:hover,
textarea:hover {
    cursor: pointer
}

#contactus input#photo,
#contactus input#resume {
    background-color: #fff
}

#contactus .inputcontainer input {
    width: 100%;
}

#contactus .error {
    font-size: .8em;
    color: #ff0002
}

#contactus fieldset#antispam {
    padding: 2px;
    border-top: 1px solid #eee;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 350px
}

#contactus fieldset#antispam legend {
    font-size: .8em;
    font-weight: 700;
    color: #333
}

#contactus .spmhidip {
    display: none;
    width: 10px;
    height: 3px
}

.ce-google-maps {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 150%
}

.ce-google-maps>* {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.ce-google-maps .gmap-opt-in-wrap {
    background-size: contain
}

.gmap-static {
    background-image: url(../iv/bernishaarwerkstattanfahrt.png);
    background-image: url(../iv/bernishaarwerkstattanfahrt.webp)
}

.ce-google-maps .gmap-opt-in-button-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .3)
}

.ce-google-maps .gmap-opt-in-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 80%;
    max-width: 20em
}

.ce-google-maps .gmap-opt-in-button div:first-child {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase
}

.ce-google-maps .gmap-opt-in-button div:last-child {
    font-size: 80%;
    line-height: 1.3
}

@media screen and (min-width: 993px) {
    .display-none-mobile {
        display: block
    }
}

@media screen and (min-width:960px) {

    .counter-style-one .counter-data .count i {
        font-size: calc(32px + .390625vw);
        font-weight: 100;
        line-height: 105px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .t-color-black-white {
        color: black;
    }

    .m-top-custom {
        margin-top: 100px
    }

    .m-top-minus {
        margin-top: -50px
    }

    .m-top-minus-l {
        margin-top: -100px
    }

    .m-top-minus-xl {
        margin-top: -150px
    }

    .min-height {
        min-height: 400px
    }

    .p-top-xs {
        padding-top: .1rem
    }

    .p-right-xs {
        padding-right: .1rem
    }

    .p-bottom-xs {
        padding-bottom: .1rem
    }

    .p-left-xs {
        padding-left: .1rem
    }

    .p-top-s {
        padding-top: .7rem
    }

    .p-right-s {
        padding-right: .7rem
    }

    .p-bottom-s {
        padding-bottom: .7rem
    }

    .p-left-s {
        padding-left: .7rem
    }

    .p-top-m {
        padding-top: 2rem
    }

    .p-right-m {
        padding-right: 2rem
    }

    .p-bottom-m {
        padding-bottom: 2rem
    }

    .p-left-m {
        padding-left: 2rem
    }

    .p-top-l {
        padding-top: 2.5rem
    }

    .p-right-l {
        padding-right: 2.5rem
    }

    .p-bottom-l {
        padding-bottom: 2.5rem
    }

    .p-left-l {
        padding-left: 2.5rem
    }

    .p-bottom-xs {
        margin-bottom: .1rem
    }

    .p-left-xs {
        margin-left: .1rem
    }

    .m-top-xs {
        margin-top: .1rem
    }

    .m-bottom-xs {
        margin-bottom: .1rem
    }

    .m-right-xs {
        margin-right: .1rem
    }

    .m-top-s {
        margin-top: .7rem
    }

    .m-right-s {
        margin-right: .7rem
    }

    .m-bottom-s {
        margin-bottom: .7rem
    }

    .m-left-s {
        margin-left: .7rem
    }

    .m-top-m {
        margin-top: 2rem
    }

    .m-left-m {
        margin-left: 2rem
    }

    .m-bottom-m {
        margin-bottom: 2rem
    }

    .m-bottom-l {
        margin-bottom: 3.2rem
    }

    .m-right-l {
        margin-right: 3.2rem
    }

    .m-top-l {
        margin-top: 3.2rem
    }

    .m-left-l {
        margin-left: 3.2rem
    }

    .m-right-m {
        margin-right: 2rem
    }
}

@media (min-width:320px) and (max-width:768px) {
    .col-sm-12 {
        width: 100%;
        flex: 0 0 auto
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-6 {
        width: 50%;
        flex: 0 0 auto
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
}

@media (min-width:768px) and (max-width:992px) {
    .col-md-12 {
        width: 100%;
        flex: 0 0 auto
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-6 {
        width: 50%;
        flex: 0 0 auto
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
}

@media (min-width:992px) {
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-6 {
        width: 50%;
        flex: 0 0 auto
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
}

@media (min-width:1200px) {

    .container,
    .container-no-p-m {
        max-width: 1140px;
        margin-right: auto;
        margin-left: auto
    }

    .col-lg-12 {
        width: 100%;
        flex: 0 0 auto
    }
}

@media (min-width:1400px) {

    .container,
    .container-no-p-m {
        max-width: 1320px;
        margin-right: auto;
        margin-left: auto
    }

    .row,
    .row-no-p-m {
        justify-content: space-between
    }
}